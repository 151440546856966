import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  },
  {
    path: "/qualifications",
    name: "qualifications",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "qualifications" */ "../views/QualificationsPage.vue"),
  },
  {
    path: "/portugal",
    name: "portugal",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "portugal" */ "../views/PortugalPage.vue"),
  },
  {
    path: "/package",
    name: "package",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "package" */ "../views/PackagePage.vue"),
  },
  {
    path: "/coverageareas",
    name: "coverageareas",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "CoverageAreas" */ "../views/CoverageAreas.vue"),
  },
  {
    path: "/BillPayPage",
    name: "BillPayPage",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "bill_pay" */ "../views/BillPayPage.vue"),
  },
  {
    path: "/offers",
    name: "offers",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "offer" */ "../views/OfferPage.vue"),
  },
  {
    path: "/ShopProductPage",
    name: "ShopProductPage",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "ShopProductPage" */ "../views/ShopProductPage.vue"),
  },
  {
    path: "/contact-us",
    name: "contact",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "ContactUsPage" */ "../views/ContactUsPage.vue"),
  },
  {
    path: "/apply",
    name: "apply",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "apply" */ "../views/ApplyPage.vue"),
  },
  {
    path: "/pre-medical",
    name: "pre-medical",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "pre-medical" */ "../views/PreMedicalPage.vue"),
  },
  {
    path: "/service_details/:id",
    name: "ServiceDetailsPage",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "ServiceDetailsPage" */ "../views/ServiceDetailsPage.vue"),
  },
  {
    path: "/Support",
    name: "SupportPage",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "SupportPage" */ "../views/SupportPage.vue"),
  },
  {
    path: "/ContactMessage",
    name: "ContactMessage",
    meta: { reload: true },
    component: () => import(/* webpackChunkName: "ContactMessage" */ "../views/ContactMessage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
